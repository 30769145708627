import { Table, Form, Row, Col, Button  } from 'react-bootstrap';
import { NFTTableEntry } from './NFTTableEntry';
import WebNFT from '../../common/types/WebNFT';
import WebNFTHistory from '../../common/types/WebNFTHistory';
import '../../areas/forSaleProperties/forSaleProperties.css';
import ProfileNFTFilters from '../../common/types/ProfileNFTFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

export interface OwnProps {
  nfts: WebNFT[],
  category: string,
  getNFTHistory: Function,
  isLoadingHistory: boolean,
  closeNFTHistory: Function,
  hasLoadingHistoryError: boolean,
  nftHistory: WebNFTHistory[],
  historyDGoodId: number,
  filters: ProfileNFTFilters,
  updateFilters: Function,
  toggleImageModal: Function,
}

export const NFTTableComponent = (props: OwnProps) => {

  const getInitialSortFilters = () => {
    return {
      ...props.filters,   
      mintSort: 0,
      nameSort: 0,
      teamSort: 0,
      seasonSort: 0,
      positionSort: 0,
      fanPointsSort: 0,
      modelSort: 0,
      gameDateSort: 0,
      opponentSort: 0,
      homeTeamSort: 0,
      variantSort: 0,
      seriesSort: 0,
      raritySort: 0,
      buildingTypeSort: 0,
      addressSort: 0,
      descriptionSort: 0,
      manufacturerSort: 0,
      legitTypeSort: 0,
    };
  }

  const renderSortIcon = (direction: number, onClick: any) => {
    if (direction === 0) {
      return (
        <div className="sort-selector-padding" onClick={onClick}>
         <FontAwesomeIcon icon={faSort} />
        </div>
      );
    }

    if (direction === 1) {
      return (
        <div className="sort-selector-padding" onClick={onClick}>
         <FontAwesomeIcon icon={faSortUp} />
        </div>
      );
    }

    if (direction === 2) {
      return (
        <div className="sort-selector-padding" onClick={onClick}>
         <FontAwesomeIcon icon={faSortDown} />
        </div>
      );
    }
  }

  const onPageClick = (direction: number) => {
    if (direction === 0 && props.filters.pageNumber > 1) {
      props.updateFilters({
        ...props.filters,
        pageNumber: props.filters.pageNumber - 1
      })
    } else if (direction === 1 && props.filters.pageNumber < Math.ceil(props.nfts.length / 100)) {
      props.updateFilters({
        ...props.filters,
        pageNumber: props.filters.pageNumber + 1
      })
    }
  }

  const updatePageNumber = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    let parsedNum = parseInt(changeEvent.currentTarget.value);

    if (isNaN(parsedNum)) {
      parsedNum = 0;
    }
    props.updateFilters({
      ...props.filters,
      pageNumber: parsedNum
    })
  }

  const updateFilterBox = (changeEvent: React.ChangeEvent<any>, varibleName: string) => {
    props.updateFilters({
      ...props.filters,
      [varibleName]: changeEvent.currentTarget.value.toUpperCase()
    } as ProfileNFTFilters)
  } 

  const updateSort = (varibleName: string, currentValue: number) => {
    let newValue = (currentValue + 1) % 3
    props.updateFilters({
      ...getInitialSortFilters(),
      [varibleName]: newValue
    })
  }

  const sortAllValues = (a: WebNFT, b: WebNFT) => {
    let sortVal = 0;
    let aVal: any = '';
    let bVal: any  = '';

    if (props.filters.mintSort !== 0) {
      sortVal = props.filters.mintSort;
      aVal = a.serialNumber;
      bVal = b.serialNumber;
    } else if (props.filters.nameSort !== 0) {
      sortVal = props.filters.nameSort;
      aVal = a.name;
      bVal = b.name;
    } else if (props.filters.teamSort !== 0) {
      sortVal = props.filters.teamSort;
      aVal = a.team;
      bVal = b.team;
    } else if (props.filters.seasonSort !== 0) {
      sortVal = props.filters.seasonSort;
      aVal = a.year;
      bVal = b.year;
    } else if (props.filters.positionSort !== 0) {
      sortVal = props.filters.positionSort;
      aVal = a.position;
      bVal = b.position;
    } else if (props.filters.fanPointsSort !== 0) {
      sortVal = props.filters.fanPointsSort;
      aVal = a.fanPoints;
      bVal = b.fanPoints;
    } else if (props.filters.modelSort !== 0) {
      sortVal = props.filters.modelSort;
      aVal = a.modelType;
      bVal = b.modelType;
    } else if (props.filters.gameDateSort !== 0) {
      sortVal = props.filters.gameDateSort;
      aVal = a.gameDate;
      bVal = b.gameDate;
    } else if (props.filters.opponentSort !== 0) {
      sortVal = props.filters.opponentSort;
      aVal = a.opponent;
      bVal = b.opponent;
    } else if (props.filters.homeTeamSort !== 0) {
      sortVal = props.filters.homeTeamSort;
      aVal = a.homeTeam;
      bVal = b.homeTeam;
    } else if (props.filters.variantSort !== 0) {
      sortVal = props.filters.variantSort;
      aVal = a.isVariant;
      bVal = b.isVariant;
    } else if (props.filters.seriesSort !== 0) {
      sortVal = props.filters.seriesSort;
      aVal = a.seriesName;
      bVal = b.seriesName;
    } else if (props.filters.raritySort !== 0) {
      sortVal = props.filters.raritySort;
      aVal = a.rarity;
      bVal = b.rarity;
    } else if (props.filters.buildingTypeSort !== 0) {
      sortVal = props.filters.buildingTypeSort;
      aVal = a.buildingType;
      bVal = b.buildingType;
    } else if (props.filters.addressSort !== 0) {
      sortVal = props.filters.addressSort;
      aVal = a.fullAddress;
      bVal = b.fullAddress;
    } else if (props.filters.descriptionSort !== 0) {
      sortVal = props.filters.descriptionSort;
      aVal = a.description;
      bVal = b.description;
    } else if (props.filters.manufacturerSort !== 0) {
      sortVal = props.filters.manufacturerSort;
      aVal = a.manufacturerName;
      bVal = b.manufacturerName;
    } else if (props.filters.legitTypeSort !== 0) {
      sortVal = props.filters.legitTypeSort;
      aVal = a.legitType;
      bVal = b.legitType;
    }

    if (sortVal === 1)
    {
      return aVal > bVal ? 1 : -1;
    } else if (sortVal === 2) {
      return bVal > aVal ? 1 : -1;
    }

    return 0;
  }

  const getFilteredAndSortedArray = () => {
    switch (props.category)
    {
      case "essential":
        return props.nfts
          .filter(p => p.name.toUpperCase().includes(props.filters.name)
            && (props.filters.team === null || props.filters.team === "" || (p.team !== null && p.team.toUpperCase().includes(props.filters.team)))
            && p.year.toUpperCase().includes(props.filters.season)
            && (props.filters.position === null || props.filters.position === "" || (p.position !== null && p.position.toUpperCase().includes(props.filters.position)))
            && p.modelType.toUpperCase().includes(props.filters.model)
            && p.legitType.toUpperCase().includes(props.filters.legitType))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "memento":
        return props.nfts
          .filter(p => p.name.toUpperCase().includes(props.filters.name)
            && (props.filters.team === null || props.filters.team === "" || (p.team !== null && p.team.toUpperCase().includes(props.filters.team)))
            && (props.filters.season === null || props.filters.season === "" || (p.year !== null && p.year.toUpperCase().includes(props.filters.season)))
            && (props.filters.position === null || props.filters.position === "" || (p.position !== null && p.position.toUpperCase().includes(props.filters.position)))
            && (props.filters.model === null || props.filters.model === "" || (p.modelType !== null && p.modelType.toUpperCase().includes(props.filters.model)))
            && (props.filters.opponent === null || props.filters.opponent === "" || (p.opponent !== null && p.opponent.toUpperCase().includes(props.filters.opponent)))
            && (props.filters.homeTeam === null || props.filters.homeTeam === "" || (p.homeTeam !== null && p.homeTeam.toUpperCase().includes(props.filters.homeTeam)))
            && (props.filters.legitType === null || props.filters.legitType === "" || (p.legitType !== null && p.legitType.toUpperCase().includes(props.filters.legitType))))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "blkexplorer":
        return props.nfts
          .filter(p => p.name.toUpperCase().includes(props.filters.name)
            && p.rarity.toUpperCase().includes(props.filters.rarity)
            && p.description.toUpperCase().includes(props.filters.description))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "spirithlwn":
        return props.nfts
          .filter(p => p.name.toUpperCase().includes(props.filters.name)
            && p.rarity.toUpperCase().includes(props.filters.rarity))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "structornmt":
        return props.nfts
          .filter(p => p.name.toUpperCase().includes(props.filters.name)
            && p.rarity.toUpperCase().includes(props.filters.rarity)
            && p.buildingType.toUpperCase().includes(props.filters.buildingType))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "structure":
        return props.nfts
          .filter(p => p.name.toUpperCase().includes(props.filters.name)
            && p.fullAddress.toUpperCase().includes(props.filters.address))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "landvehicle":
        return props.nfts
          .filter(p => p.name.toUpperCase().includes(props.filters.name))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "outdoordecor":
        return props.nfts
          .filter(p => p.manufacturerName.toUpperCase().includes(props.filters.manufacturer))
          .sort(sortAllValues)
          .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
      case "pass":
        return props.nfts
        .filter(p => p.name.toUpperCase().includes(props.filters.name)
          && p.year.toUpperCase().includes(props.filters.season)
          && p.modelType.toUpperCase().includes(props.filters.model)
          && p.legitType.toUpperCase().includes(props.filters.legitType))
        .sort(sortAllValues)
        .slice((props.filters.pageNumber - 1) * 100, (props.filters.pageNumber * 100));
    }

    return [];
  }

  const getSpecificColumnHeaders = () => {
    switch (props.category)
    {
      case "essential":
        return (
          <>
            <th>
              <div className="filter-col">
                <Form.Label>Team</Form.Label>
                {renderSortIcon(props.filters.teamSort, () => updateSort("teamSort", props.filters.teamSort))}
                <Form.Control size="sm" type="text" value={props.filters.team} onChange={(event) => updateFilterBox(event, "team")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Season</Form.Label>
                {renderSortIcon(props.filters.seasonSort, () => updateSort("seasonSort", props.filters.seasonSort))}
                <Form.Control size="sm" type="text" value={props.filters.season} onChange={(event) => updateFilterBox(event, "season")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Position</Form.Label>
                {renderSortIcon(props.filters.positionSort, () => updateSort("positionSort", props.filters.positionSort))}
                <Form.Control size="sm" type="text" value={props.filters.position} onChange={(event) => updateFilterBox(event, "position")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Points</Form.Label>
                {renderSortIcon(props.filters.fanPointsSort, () => updateSort("fanPointsSort", props.filters.fanPointsSort))}
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Model</Form.Label>
                {renderSortIcon(props.filters.modelSort, () => updateSort("modelSort", props.filters.modelSort))}
                <Form.Control size="sm" type="text" value={props.filters.model} onChange={(event) => updateFilterBox(event, "model")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Variant</Form.Label>
                {renderSortIcon(props.filters.variantSort, () => updateSort("variantSort", props.filters.variantSort))}
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Type</Form.Label>
                {renderSortIcon(props.filters.legitTypeSort, () => updateSort("legitTypeSort", props.filters.legitTypeSort))}
                <Form.Control size="sm" type="text" value={props.filters.legitType} onChange={(event) => updateFilterBox(event, "legitType")} />
              </div>
            </th>
          </>
        );
      case "memento":
        return (
          <>
            <th>
              <div className="filter-col">
                <Form.Label>Team</Form.Label>
                {renderSortIcon(props.filters.teamSort, () => updateSort("teamSort", props.filters.teamSort))}
                <Form.Control size="sm" type="text" value={props.filters.team} onChange={(event) => updateFilterBox(event, "team")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Season</Form.Label>
                {renderSortIcon(props.filters.seasonSort, () => updateSort("seasonSort", props.filters.seasonSort))}
                <Form.Control size="sm" type="text" value={props.filters.season} onChange={(event) => updateFilterBox(event, "season")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Position</Form.Label>
                {renderSortIcon(props.filters.positionSort, () => updateSort("positionSort", props.filters.positionSort))}
                <Form.Control size="sm" type="text" value={props.filters.position} onChange={(event) => updateFilterBox(event, "position")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Points</Form.Label>
                {renderSortIcon(props.filters.fanPointsSort, () => updateSort("fanPointsSort", props.filters.fanPointsSort))}
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Model</Form.Label>
                {renderSortIcon(props.filters.modelSort, () => updateSort("modelSort", props.filters.modelSort))}
                <Form.Control size="sm" type="text" value={props.filters.model} onChange={(event) => updateFilterBox(event, "model")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Date</Form.Label>
                {renderSortIcon(props.filters.gameDateSort, () => updateSort("gameDateSort", props.filters.gameDateSort))}
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Opponent</Form.Label>
                {renderSortIcon(props.filters.opponentSort, () => updateSort("opponentSort", props.filters.opponentSort))}
                <Form.Control size="sm" type="text" value={props.filters.opponent} onChange={(event) => updateFilterBox(event, "opponent")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Home</Form.Label>
                {renderSortIcon(props.filters.homeTeamSort, () => updateSort("homeTeamSort", props.filters.homeTeamSort))}
                <Form.Control size="sm" type="text" value={props.filters.homeTeam} onChange={(event) => updateFilterBox(event, "homeTeam")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Type</Form.Label>
                {renderSortIcon(props.filters.legitTypeSort, () => updateSort("legitTypeSort", props.filters.legitTypeSort))}
                <Form.Control size="sm" type="text" value={props.filters.legitType} onChange={(event) => updateFilterBox(event, "legitType")} />
              </div>
            </th>
          </>
        );
      case "blkexplorer":
        return (
          <>
            <th>
              <div className="filter-col">
                <Form.Label>Series</Form.Label>
                {renderSortIcon(props.filters.seriesSort, () => updateSort("seriesSort", props.filters.seriesSort))}
                <Form.Control size="sm" type="text" value={props.filters.series} onChange={(event) => updateFilterBox(event, "series")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Rarity</Form.Label>
                {renderSortIcon(props.filters.raritySort, () => updateSort("raritySort", props.filters.raritySort))}
                <Form.Control size="sm" type="text" value={props.filters.rarity} onChange={(event) => updateFilterBox(event, "rarity")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Description</Form.Label>
                {renderSortIcon(props.filters.descriptionSort, () => updateSort("descriptionSort", props.filters.descriptionSort))}
                <Form.Control size="sm" type="text" value={props.filters.description} onChange={(event) => updateFilterBox(event, "description")} />
              </div>
            </th>
          </>
        );
      case "spirithlwn":
        return (
          <>
            <th>
              <div className="filter-col">
                <Form.Label>Rarity</Form.Label>
                {renderSortIcon(props.filters.raritySort, () => updateSort("raritySort", props.filters.raritySort))}
                <Form.Control size="sm" type="text" value={props.filters.rarity} onChange={(event) => updateFilterBox(event, "rarity")} />
              </div>
            </th>
          </>
        );
      case "structornmt":
        return (
          <>
            <th>
              <div className="filter-col">
                <Form.Label>Rarity</Form.Label>
                {renderSortIcon(props.filters.raritySort, () => updateSort("raritySort", props.filters.raritySort))}
                <Form.Control size="sm" type="text" value={props.filters.rarity} onChange={(event) => updateFilterBox(event, "rarity")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Building</Form.Label>
                {renderSortIcon(props.filters.buildingTypeSort, () => updateSort("buildingTypeSort", props.filters.buildingTypeSort))}
                <Form.Control size="sm" type="text" value={props.filters.buildingType} onChange={(event) => updateFilterBox(event, "buildingType")} />
              </div>
            </th>
          </>
        );
      case "structure":
        return (
          <>
            <th>
              <div className="filter-col">
                <Form.Label>Address</Form.Label>
                {renderSortIcon(props.filters.addressSort, () => updateSort("addressSort", props.filters.addressSort))}
                <Form.Control size="sm" type="text" value={props.filters.address} onChange={(event) => updateFilterBox(event, "address")} />
              </div>
            </th>
          </>
        );
      case "landvehicle":
        return (
          <>
          </>
        );
      case "outdoordecor":
        return (
          <>
          <th>
            <div className="filter-col">
              <Form.Label>Manufacturer</Form.Label>
              {renderSortIcon(props.filters.manufacturerSort, () => updateSort("manufacturerSort", props.filters.manufacturerSort))}
              <Form.Control size="sm" type="text" value={props.filters.manufacturer} onChange={(event) => updateFilterBox(event, "manufacturer")} />
            </div>
          </th>
          </>
        );
      case "pass":
        return (
          <>
            <th>
              <div className="filter-col">
                <Form.Label>Season</Form.Label>
                {renderSortIcon(props.filters.seasonSort, () => updateSort("seasonSort", props.filters.seasonSort))}
                <Form.Control size="sm" type="text" value={props.filters.season} onChange={(event) => updateFilterBox(event, "season")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Points</Form.Label>
                {renderSortIcon(props.filters.fanPointsSort, () => updateSort("fanPointsSort", props.filters.fanPointsSort))}
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Model</Form.Label>
                {renderSortIcon(props.filters.modelSort, () => updateSort("modelSort", props.filters.modelSort))}
                <Form.Control size="sm" type="text" value={props.filters.model} onChange={(event) => updateFilterBox(event, "model")} />
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Type</Form.Label>
                {renderSortIcon(props.filters.legitTypeSort, () => updateSort("legitTypeSort", props.filters.legitTypeSort))}
                <Form.Control size="sm" type="text" value={props.filters.legitType} onChange={(event) => updateFilterBox(event, "legitType")} />
              </div>
            </th>
          </>
        );
    }
  }

  return (
    <div className="bg-light header-min-width">
      <Table>
        <thead>
          <tr className="form-aligns">
            <th>
              <div className="filter-col">
                <Form.Label>Mint/Current/Max</Form.Label>
                {renderSortIcon(props.filters.mintSort, () => updateSort("mintSort", props.filters.mintSort))}
              </div>
            </th>
            <th>
              <div className="filter-col">
                <Form.Label>Name</Form.Label>
                {renderSortIcon(props.filters.nameSort, () => updateSort("nameSort", props.filters.nameSort))}
                <Form.Control size="sm" type="text" value={props.filters.name} onChange={(event) => updateFilterBox(event, "name")} />
              </div>
            </th>
            {getSpecificColumnHeaders()}
            <th>
              <Form.Label>Owner</Form.Label>
            </th>
            <th>
              <Form.Label></Form.Label>
            </th>
          </tr>
        </thead>
        <tbody>
          {props.nfts && getFilteredAndSortedArray()
            .map((n) => 
            <NFTTableEntry 
              key={n.dGoodId} 
              nft={n} 
              category={props.category}
              getNFTHistory={props.getNFTHistory}
              isLoadingHistory={props.isLoadingHistory}
              closeNFTHistory={props.closeNFTHistory}
              hasLoadingHistoryError={props.hasLoadingHistoryError}
              nftHistory={props.nftHistory}
              historyDGoodId={props.historyDGoodId}
              toggleImageModal={props.toggleImageModal}
          />)}
        </tbody>
      </Table>
      <div className="page-selector-class">
        <Row>
          <Button className="page-selector-left" onClick={() => onPageClick(0)}><FontAwesomeIcon icon={faArrowLeft} /></Button>
          <Col className="page-selector-center">
            <Row>
              <Col className="page-selector-center-left"><Form.Label>Page</Form.Label></Col>
              <Col className="page-selector-center-right"><Form.Control size="sm" placeholder="Page" type="text" value={props.filters.pageNumber} onChange={updatePageNumber} /></Col>
            </Row>
          </Col>
          <Button className="page-selector-right" onClick={() => onPageClick(1)}><FontAwesomeIcon icon={faArrowRight} /></Button>
        </Row>
      </div>
    </div>
  );
}
